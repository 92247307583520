@use 'styles/vars';
@use 'styles/global/text';

.floatingHeader {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.closeButtonWrapper {
  background: rgba(vars.$colors-white, 0.4);
}

.closeButtonWrapper:hover {
  background: vars.$colors-white;
}

.actionBarButton {
  flex: 1 1 auto;
  padding: vars.$content-spacing;
  font-weight: text.$fw-medium;

  span {
    display: none;
  }

  @include vars.tablet-up() {
    span {
      display: inline;
    }
  }
}

.actionBarButton:disabled {
  color: vars.$colors-grey;
}

.actionBarButton:not(:last-child) {
  border-right: 1px solid rgba(vars.$colors-grey, 0.35);
}

.actionBarButton:hover:not([disabled]) {
  color: vars.$colors-white;
  background-color: vars.$colors-indigo;
}