@use 'styles/vars' as *;
@use 'styles/functions' as *;
@use 'styles/global/text' as *;

.eventCard {
  min-height: 220px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.eventHeading {
  @extend .text-metadata;
  margin-left: $content-spacing;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 0.4rem;
}

.calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid $colors-blue;
  text-transform: uppercase;
  color: $colors-blue;
  border-radius: 4px;
  font-size: $fs-xx-large;
  font-weight: $fw-medium;
  min-width: 60px;

  &.indigo {
    border: 2px solid $colors-white;
    color: $colors-white;
  }

  .calendarHeading {
    color: $colors-white;
    background-color: $colors-blue;
    font-size: $fs-small;
    width: 100%;
    text-align: center;
    line-height: 22px;

    &.indigo {
      color: $colors-indigo-light;
      background-color: $colors-white;
    }
  }

  .calendarDate {
    line-height: 44px;
  }
}

.eventLocation {
  @extend .text-link;
  font-size: $fs-small;
}

.previewDescription {
  @include clamp-lines(2, getLineHeight('h4'));
  @extend .text-h4;
  margin: $content-spacing 0;
}

.eventPreviewContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.previewContent {
  @include clamp-lines(2, getLineHeight('body'));
  @extend .text-body;
  font-size: $fs-small;
  margin-bottom: $content-spacing;
}