@use 'styles/vars' as *;
@use 'styles/functions' as *;

.contentWrapper {
  overflow-y: auto;
  background-color: $colors-grey-medium;
  height: 100%;
}

.empty {
  margin: 0;

  @include tablet-down() {
    margin: $half-spacing;
  }
}

.unreadMarker {
  &::after {
    content: '';
    position: absolute;
    background-color: $colors-orange;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    left: $quarter-spacing;
    top: $quarter-spacing;
  }
}

.listItemText {
  margin-right: $content-spacing;

  >span {
    @include clamp-lines(2, getLineHeight('body2'));
  }
}