@use 'styles/vars' as *;
@use 'styles/functions' as *;
@use 'styles/global/text' as *;

.messagesContainer {
  height: 60vh;
  display: inline-flex;
  flex-direction: column;
  background-color: $colors-white; // The dialog itself has a grey background, to make the sidebar grey and prevent flickering of the corners when opening it
  border-radius: $message-border-radius;
  width: 100%;
  transition: width $transition-time $transition-ease-in-out, border-radius $transition-time $transition-ease-in-out;

  &.containerWithSidebar {
    width: 70%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    @include tablet-down() {
      width: 0;
      overflow: hidden;
    }
  }

  &.large {
    height: 80vh;
  }

  &.fullScreen {
    height: 100vh;
  }

  @include mobile-down() {
    height: 100%;
  }
}

.emptyMessage {
  display: flex;
  align-items: center;
  margin-top: $section-spacing;
  margin-bottom: 5rem;
  color: $colors-grey;
  font-weight: $fw-medium;
  font-size: $fs-tiny;
}

.byline {
  color: $colors-grey;
  margin-left: $quarter-spacing;
  display: inline;
}

.eventMessage {
  color: $colors-indigo-light;
}

.content {
  overflow-x: hidden;
  height: 100%;
  @include scrollbar;

  &::-webkit-scrollbar-track {
    background: initial;
  }
}

.messages {
  position: relative;
  padding: $content-spacing;
  min-width: 250px;

  @include mobile-down() {
    padding: $half-spacing;
  }
}

.hideMessages {
  visibility: hidden;
  width: 0%;
  padding: 0;
}

.sidebarContainer {
  position: relative;
  width: 0;
  overflow: hidden;
  transition: width $transition-time $transition-ease-in-out;

  &.sidebarContainerOpen {
    width: 30%;

    @include tablet-down() {
      width: 100%;
    }
  }
}

.textField {
  border-radius: 8px;
  padding: $content-spacing;
  background-color: $colors-grey-light;
}

.todayDividerLine {
  height: 1px;
  width: 100%;
  border: 1px solid $colors-grey-medium;
  align-self: center;
}

.todayDividerText {
  @extend .text-subheading;
  font-size: $fs-tiny;
  min-width: 4rem;
  border-radius: 20px;
  background-color: $colors-grey-light;
  text-align: center;
  text-transform: uppercase;
  line-height: 30px;
  margin: 0 $half-spacing;
}

.settingsButton {
  display: flex;
  align-items: center;
  margin: 0 $content-spacing;
  padding: $quarter-spacing $half-spacing;
  @extend .text-body;
  font-size: $fs-small;
  background-color: $colors-blue-very-light;
  border-radius: 50px;
  cursor: pointer;
  white-space: nowrap;
}

.dialogTitle {
  min-height: $chat-header-height;
  z-index: 1;

  &::after {
    // Adds a bottom border that spans the whole dialog, important for when the chat sidebar is open
    content: '';
    height: 2px;
    width: calc(100vw - 64px); // Width of the MUI dialog by default
    max-width: 1280px; // Max width of the MUI dialog by default
    background-color: $colors-grey-medium;
    position: absolute;
    bottom: 0;
    left: 0;

    @include tablet-down() {
      width: 100%;
    }
  }

  @include desktop-down() {
    min-height: $chat-header-height-small;
  }

  @include tablet-down() {
    height: initial;
  }

  @include mobile-down() {
    font-size: $fs-large;
  }
}

.backdrop {
  z-index: 1300; // z-index of mui dialogs, it has to be the same so it is interoperable when navigating dialog-in-dialog
}

.chatDialog {
  position: fixed;
  opacity: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1300; // z-index of mui dialogs, it has to be the same so it is interoperable when navigating dialog-in-dialog
  width: calc(100% - 64px);
  max-width: $screen-size-lg;
  background-color: $colors-grey-light;
  border-radius: $message-border-radius;
  display: flex;

  @include tablet-down() {
    width: 100%;
    border-radius: 0;
  }

  &.fullScreen {
    width: 100%;
    max-width: unset;
  }

  @include mobile-down() {
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 1;
    max-height: none;
    border-radius: 0px;
    left: 0;
    top: 0;
    transform: none;
  }
}

.requestCard {
  margin-bottom: $content-spacing;
  border-radius: 8px;
  width: 450px;

  @include tablet-down() {
    width: unset;
  }

  @include mobile-down() {
    padding: $content-spacing;
  }

  .requestCardHeader {
    display: flex;
    padding-bottom: $content-spacing;
    border-bottom: 1px solid $colors-grey-medium;
  }

  .requestCardText {
    margin-bottom: $content-spacing;
    margin-top: $half-spacing;
    white-space: pre-line;
    overflow-wrap: break-word;
  }
}

.requestStrip {
  height: 64px;
  background-color: $colors-blue;
  color: $colors-white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  font-weight: $fw-medium;

  .requestStripIcon {
    @include mobile-down() {
      display: none;
    }
  }
}

.conversationHeader {
  @include clamp-lines(2, getLineHeight('mui-dialog-title'));

  @include tablet-up {
    @include clamp-lines(1, getLineHeight('mui-dialog-title'));
  }
}