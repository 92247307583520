@use 'styles/vars' as *;

.player {
  background-color: $colors-grey-medium;
  border: 1px solid $colors-grey-medium;
}

.placeholder {
  width: 101px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px dashed $colors-grey-medium;
  color: $colors-grey-medium;

  &:hover {
    cursor: pointer;
    background-color: $colors-grey-medium;
    color: $colors-indigo;
  }
}

.preview {
  position: relative;
  width: 101px;
  height: 56px;

  img {
    line-height: 22px;
    text-align: center;
    width: 101px;
    height: 56px;
  }

  img:before {
    content: ' ';
    position: absolute;
    display: block;
    top: 0;

    height: calc(100% - 2px);
    width: 100%;
    background-color: $colors-grey-medium;
    border: 1px solid $colors-grey-medium;
  }

  img:after {
    content: '\25B6';
    position: absolute;

    display: block;
    top: calc(50% - 11px);
    left: calc(50% - 6px);
    text-align: center;
  }

  &:hover {
    cursor: pointer;
  }
}