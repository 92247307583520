@use 'styles/vars' as *;
@use 'styles/global/text' as *;

.byline {
  color: $colors-grey;
  margin-left: $quarter-spacing;
  display: inline;
}

.timestampSuffixLeft {
  font-size: $fs-tiny;
  grid-column: 2;
}

.timestampSuffixRight {
  font-size: $fs-tiny;
  grid-column: 1;
}

.author {
  @extend .text-body;
  font-weight: $fw-medium;

  &:hover {
    text-decoration: underline;
  }
}

.authorContainer {
  color: $colors-indigo;
  font-size: $fs-small;
  grid-column: 2;
}

.messageBubble {
  grid-column: 2;
  max-width: 36rem;

  @include mobile-down() {
    max-width: 100%;
  }
}

.messageCard {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 48px minmax(200px, 1fr);
  justify-items: start;
  margin-bottom: $half-spacing;

  .authorImage {
    grid-row: 1/3;
    padding-top: $quarter-spacing;
  }

  &.cardRight {
    justify-items: end;
    grid-template-columns: minmax(200px, 1fr) 48px;

    .authorContainer {
      grid-column: 1;
    }

    .authorImage {
      grid-column: 2;
    }

    .messageBubble {
      grid-column: 1;
    }
  }
}

.message {
  padding: $content-spacing;
  border-radius: $message-border-radius;
  min-width: 8rem;

  &.ownMessage {
    background-color: $colors-indigo;
    color: $colors-white;
    margin-left: auto;
    border-top-right-radius: 0;

    a {
      color: $colors-blue-light;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.othersMessage {
    background-color: $colors-grey-light;
    border-top-left-radius: 0;
  }
}

.indent {
  margin-left: 3.75rem;
}

.messageFormButtons {
  display: flex;
  margin-left: $content-spacing;
  align-self: flex-end;
}

.attachmentList {
  display: grid;
  grid-gap: 3px;
  margin-top: $half-spacing;
}