@use 'styles/vars' as *;

.bannerWrapper {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 24%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.banner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -$content-spacing;
  margin-top: -$content-spacing;
  margin-bottom: $content-spacing;
  margin-right: -$content-spacing;
}