$z-layer-1: 10;
$z-layer-2: 20;
$z-layer-3: 30;
$z-layer-4: 40;
$z-layer-5: 50;
$z-layer-6: 60;

$z-app: $z-layer-1;
$z-veil: $z-layer-4;
$z-veil-focus: $z-layer-5;
$z-overpowered: $z-layer-6;

.z {
  &-layer-1 {
    z-index: $z-layer-1;
  }

  &-layer-2 {
    z-index: $z-layer-2;
  }

  &-layer-3 {
    z-index: $z-layer-3;
  }

  &-layer-4 {
    z-index: $z-layer-4;
  }

  &-layer-5 {
    z-index: $z-layer-5;
  }

  &-layer-6 {
    z-index: $z-layer-6;
  }
}
