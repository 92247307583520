@use 'styles/vars';

.setupStep {
  display: flex;
  align-items: center;
  text-align: left;
  transition: background-color 300ms;
}


.checklistLink {
  display: block;
  width: 100%;

  &:focus {
    outline-color: vars.$colors-grey-medium;
    outline-width: 1px;
    outline-style: solid;
    -moz-outline-radius: 8px;
  }
}