@use 'styles/vars';

.impactGoalContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: vars.$quarter-spacing;

  &.readOnly {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  .impactGoal {
    cursor: pointer;
    filter: none;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    transition: all 300ms ease;

    &:hover {
      filter: none;
      -webkit-filter: grayscale(100%) brightness(0.8);
      -moz-filter: grayscale(100%) brightness(0.8);
      -ms-filter: grayscale(100%) brightness(0.8);
      -o-filter: grayscale(100%) brightness(0.8);
    }

    &.activeGoal {
      filter: none;
      -webkit-filter: grayscale(0%);
      -moz-filter: grayscale(0%);
      -ms-filter: grayscale(0%);
      -o-filter: grayscale(0%);
    }
  }

  @include vars.mobile-down() {
    grid-template-columns: repeat(auto-fill, minmax(min(105px, 140px), 1fr));
  }
}