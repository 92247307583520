@use 'styles/vars' as *;
@use 'styles/global/text' as *;

.box {
  padding: $content-spacing;
  border-radius: $default-border-radius;
  background-color: $colors-blue-very-light;

  @extend .text-small;

  @include desktop-up() {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.icon {
  line-height: 0;
  margin-right: $half-spacing;

  @include mobile-up() {
    margin-right: $content-spacing;
  }
}

.title {
  display: flex;
  align-items: center;
  flex-direction: row;
}