@use 'styles/vars';
@use 'styles/global/text';

.chartLabel {
  @extend .text-metadata;
  padding: vars.$half-spacing;
  border-radius: 5px;
  background-color: vars.$colors-indigo;
  opacity: 0.9;
  font-weight: text.$fw-bold;
  color: vars.$colors-white;
}