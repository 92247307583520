@use 'styles/vars' as *;

.dropzone {
  width: 100%;
  border: 2px dashed $colors-blue;
  margin: auto;
  height: 100px;
  display: flex;
  flex-grow: 1;
  border-radius: $message-border-radius;

  &.leftAlign {
    height: unset;
  }
}

.dropWrapper {
  width: 100%;
}

.dropzone:hover {
  cursor: pointer;
}

.dropText {
  width: 80%;
  margin: auto;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.leftAlign {
    margin: 0;
    text-align: left;
    padding: $half-spacing 0 $half-spacing $content-spacing;
  }
}