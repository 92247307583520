@use 'styles/vars';

.unselectButton {
  &:hover {
    &>* {
      visibility: hidden;
    }

    &:after {
      position: absolute;
      top: 6px;
      left: 0;
      width: 100%;
      font-size: 1.25em;
      content: 'X';
    }
  }
}