@use 'styles/vars' as *;

.nav {
  position: -webkit-sticky;
  position: sticky;
  top: $content-spacing;
  display: flex;
  flex-direction: column;
  margin-right: $content-spacing;

  @include tablet-down() {
    display: none;
  }

  >button {
    color: $colors-grey;
    line-height: 1.33;
    padding: $quarter-spacing 0;
    margin: $quarter-spacing 0;

    &:focus {
      color: $colors-grey;
    }

    &:hover {
      color: $colors-indigo-dark;
    }

    &.activeTab {
      color: $colors-indigo-dark;
    }
  }
}