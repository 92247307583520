@use 'styles/vars' as *;
@use 'styles/global/text' as *;
@use 'styles/functions' as *;

.shareCard {
  width: 245px;
  cursor: default;
  &.updateCard {
    min-width: 245px;
    width: fit-content;
  }
}

.shareCardImage {
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
}

.mission {
  @include clamp-lines(2, getLineHeight('body'));
  @extend .text-body;
}

.eventShareCardBackground {
  display: flex;
  align-items: center;
  color: $colors-white;
  height: 128px;
  background-color: $colors-indigo-light;
  width: 100%;
  padding: $content-spacing;
  gap: $content-spacing;
}

.eventLocation {
  @extend .text-link;
  font-size: $fs-small;
}

.eventTitle {
  @include clamp-lines(3, getLineHeight('body'));
}

.organizationIcon {
  outline: 2px solid $colors-grey-medium;
  border-radius: 50%;
}

.moreOrganizationsIcon {
  min-width: 32px;
  min-height: 32px;
  background-color: $colors-indigo-medium;
  color: $colors-white;
  @extend .text-metadata;
  font-weight: $fw-bold;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 2px solid $colors-grey-medium;
  border-radius: 50%;
}
