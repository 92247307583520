@use 'styles/vars' as *;

.byline {
  display: flex;

  a,
  button {
    transition: color $transition-time-shortest $transition-hover;

    &:hover {
      color: $colors-blue;
    }
  }

  @include mobile-up() {
    align-items: center;
  }
}