@use 'styles/vars' as *;
@use 'styles/global/text' as *;

.indicator {
  $indicator-size: $content-spacing;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: $fs-tiny;
  font-weight: $fw-medium;
  color: $colors-white;
  height: $indicator-size;
  min-width: $indicator-size;
  background: $colors-orange-medium;
  border-radius: 50%;
  line-height: $indicator-size;
  padding: 1px;
}

.indicatorBlue {
  background: transparent;
  color: $colors-blue;
  border: 1px solid $colors-blue;
  line-height: 1;
  font-weight: 500;
}

.indicatorGrey {
  background: transparent;
  color: $colors-grey;
  border: 1px solid $colors-grey;
  line-height: 1;
  font-weight: 500;
}

.indicator.square {
  border-radius: $default-border-radius;
  padding: 0 2px;
}