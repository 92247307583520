@use 'styles/vars' as *;
@use "sass:color";

.infoBox {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile-up() {
    flex-direction: row;
    padding-right: $section-spacing;
  }
}

.icon {
  color: $colors-blue;
  line-height: 0;

  @include mobile-up() {
    margin-right: $content-spacing;
  }
}

.infoBoxHeader {
  margin: 0;
}

.red {
  background-color: $colors-red-light;
  border: 1px solid $colors-red;
}

.blue {
  background-color: color.adjust($colors-blue-very-light, $lightness: 5%);
  border: 1px solid $colors-blue-light;
}

.green {
  background-color: color.adjust($colors-green-light, $lightness: 5%);
  border: 1px solid $colors-green;

  svg {
    color: $colors-green;
  }
}

.transparent {
  border-color: transparent;
}