@use 'sass:math';
@use './functions' as *;

// Primary Colors
$colors-blue: #346aff;
$colors-blue-pale: #4e7af0;
$colors-blue-medium: #85a6ff;
$colors-blue-light: #acb9ff;
$colors-blue-very-light: #d6e1ff;
$colors-blue-dark: #00268f;
$colors-blue-black: #00172f;

$colors-indigo: #0f1f42;
$colors-indigo-very-light: #36425c;
$colors-indigo-light: #273554;
$colors-indigo-medium: #3f3356;
$colors-indigo-dark: #0b1936;

// Neutral Colors
$colors-black: #141516;
$colors-grey-dark: #606276;
$colors-grey: #7e8a96;
$colors-grey-medium: #eaecee;
$colors-grey-light: #f6f7fb;
$colors-grey-very-light: #fbfbfb;
$colors-grey-disabled: #d6dade;
$colors-white: #ffffff;

// Accent Colors
$colors-orange: #ff6a36;
$colors-orange-medium: #fa8f68;
$colors-orange-light: #ffe1d7;
$colors-orange-dark: #cc552b;

$colors-purple: #ac4df7;
$colors-purple-medium: #d697ff;
$colors-purple-light: #f1dcff;

$colors-violet: #855cf8;
$colors-violet-medium: #b085ff;
$colors-violet-light: #d4bdff;
$colors-violet-very-light: #cdcbff;
$colors-violet-dark: #503795;

$colors-red: #ff5454;
$colors-red-medium: #fca2a7;
$colors-red-light: #fce6e8;
$colors-red-dark: #f50004;

$colors-yellow: #fcd24c;
$colors-yellow-medium: #ffe997;
$colors-yellow-light: #fff8dc;
$colors-yellow-dark: #ad8400;

$colors-lime: #b9e541;
$colors-lime-medium: #d6f291;
$colors-lime-light: #f6ffe0;

$colors-green-darker: #0b373c;
$colors-green-dark: #388e55;
$colors-green: #4fe88d;
$colors-green-medium: #84c89b;
$colors-green-light: #b5dbd0;
$colors-green-very-light: #e5f4e9;

$content-spacing: 1.25rem;
$section-spacing: $content-spacing * 2;
$half-spacing: math.div($content-spacing, 2);
$quarter-spacing: math.div($content-spacing, 4);
$eighth-spacing: math.div($content-spacing, 8);

$font-root-size: 16px;

$transition-time-shortest: 150ms;
$transition-time-shorter: 200ms;
$transition-time-short: 250ms;
$transition-time: 300ms;

$transition-ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);
$transition-hover: cubic-bezier(0.25, 0.8, 0.25, 1);
$default-border-radius: 4px;
$message-border-radius: 8px;

$boxShadow: 0 0 $quarter-spacing rgba(0, 0, 0, 0.07);
$boxShadow-hover: 0 0 $half-spacing rgba(0, 0, 0, 0.07);

$mobile-navbar-height: 60px;
$navbar-logo-height: 80px;
$chat-header-height: 130px;
$chat-header-height-small: 92px;

$screen-size-xs: 360px;
$screen-size-sm: 600px;
$screen-size-md: 960px;
$screen-size-lg: 1280px;
$screen-size-xl: 1920px;

$icon-sizes: (
  'small': 16px,
  'normal': 18px,
  'large': 24px,
  'big': 48px,
  'huge': 72px,
);

@mixin scrollbar {
  // firefox
  scrollbar-width: thin;

  // width
  &::-webkit-scrollbar {
    width: 8px;
  }

  // Track
  &::-webkit-scrollbar-track {
    background: $colors-indigo-dark;
  }

  // Handle
  &::-webkit-scrollbar-thumb {
    background: $colors-grey;
  }

  // Handle on hover
  &::-webkit-scrollbar-thumb:hover {
    background: $colors-grey-dark;
  }

}

@mixin break-words {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
}

@mixin between($from, $to) {
  @media (min-width: $from) and (max-width: calc($to - 1px)) {
    @content;
  }
}

@mixin desktop-only {
  @include desktop-down() {
    display: none;
  }
}

@mixin tablet-up-only {
  @include tablet-down() {
    display: none;
  }
}

@mixin desktop-up-only {
  @include desktop-down() {
    display: none;
  }
}

@mixin xl-up-only {
  @include xl-down() {
    display: none;
  }
}

@mixin tablet-down-only {
  @include tablet-up() {
    display: none;
  }
}

@mixin mobile-down-only {
  @include mobile-up() {
    display: none;
  }
}

@mixin xs-up {
  @media (min-width: $screen-size-xs) {
    @content;
  }
}

@mixin xs-down {
  @media (max-width: $screen-size-xs) {
    @content;
  }
}

@mixin mobile-up {
  @media (min-width: $screen-size-sm) {
    @content;
  }
}

@mixin mobile-down {
  @media (max-width: $screen-size-sm) {
    @content;
  }
}

@mixin tablet-up {
  @media (min-width: $screen-size-md) {
    @content;
  }
}

@mixin desktop-up {
  @media (min-width: $screen-size-lg) {
    @content;
  }
}

@mixin xl-up {
  @media (min-width: $screen-size-xl) {
    @content;
  }
}

@mixin xl-down {
  @media (max-width: $screen-size-xl) {
    @content;
  }
}

@mixin tablet-down {
  @media (max-width: $screen-size-md) {
    @content;
  }
}

@mixin desktop-down {
  @media (max-width: $screen-size-lg) {
    @content;
  }
}

@mixin mobile-up-only {
  @include mobile-down() {
    display: none;
  }
}

@mixin desktop-down-only {
  display: inherit;

  @include desktop-up() {
    display: none;
  }
}

@mixin triangle-up($size: 6px, $color: $colors-blue) {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 $size $size $size;
  border-color: transparent transparent $color transparent;
}

@mixin triangle-down($size: 6px, $color: $colors-blue) {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: $size $size 0 $size;
  border-color: $color transparent transparent transparent;
}

@mixin line-height($key) {
  $lh: getLineHeight($key);
  line-height: $lh;
}

@mixin clamp-lines($lines-to-show, $line-height: getLineHeight("body")) {
  display: -webkit-box;
  -webkit-line-clamp: #{$lines-to-show};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 1em * ($line-height * $lines-to-show);
}