.container {
  display: flex;
  align-items: center;

  > :not(.logo) {
    margin-left: 1em;
  }
}

.logo {
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.logo.square {
  border-radius: 0.25em;
}