@use 'sass:list';
@use '../vars';

@mixin position($top, $right: $top, $bottom: $top, $left: $top) {
  top: $top;
  left: $left;
  bottom: $bottom;
  right: $right;
}

@mixin increase-hitbox($size: vars.$half-spacing) {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    display: block;
    @include position(-$size);
  }
}

$valid-alignment: left, center, right, justify;

@mixin alignment($alignment) {
  @if not list.index($valid-alignment, $alignment) {
    @error "#{$alignment} is not a valid alignment. Expected one of #{$valid-alignment}.";
  }

  @else {
    text-align: $alignment;
  }
}