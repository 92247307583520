@use 'styles/vars' as *;
@use 'styles/global/text' as *;

.sidebar {
  min-width: 250px;
  height: 100%;
}

.header {
  min-height: $chat-header-height;

  @include desktop-down() {
    min-height: $chat-header-height-small;
  }

  @include tablet-down() {
    height: $chat-header-height-small;
    border-bottom: 2px solid $colors-grey-medium;
  }
}

.sidebarContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  max-height: calc(60vh - $chat-header-height);
  @include scrollbar;

  &::-webkit-scrollbar-track {
    background: initial;
  }

  @include tablet-down() {
    max-height: calc(100vh - $chat-header-height);
  }

  &.large {
    max-height: calc(80vh - $chat-header-height);
  }

  &.fullScreen {
    max-height: calc(100vh - $chat-header-height);
  }
}

.renameChat {
  display: flex;
  justify-content: space-between;
  @extend .text-body;
  font-weight: $fw-medium;
  padding: $content-spacing $section-spacing;
  border-bottom: 2px solid $colors-grey-medium;
  cursor: pointer;

  @include desktop-down() {
    padding: $content-spacing $content-spacing;
  }
}

.clickableCard {
  padding: $half-spacing $section-spacing;

  @include desktop-down() {
    padding: $half-spacing $content-spacing;
  }

  &:hover {
    background-color: $colors-white;
  }
}

.clickableReportCard {
  color: grey;
  @extend .clickableCard;
  @extend .text-body;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: $content-spacing;
}

.addPerson {
  margin: $half-spacing $section-spacing $content-spacing $section-spacing;

  @include desktop-down() {
    margin: $half-spacing $content-spacing $content-spacing $content-spacing;
  }
}