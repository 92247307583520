@use 'styles/vars' as *;

.textFilter {
  display: inline-block;
  overflow: hidden;
  max-width: 0;
  transition: max-width 0.2s $transition-hover;
}

.textFilterOpen {
  max-width: 230px;
  width: auto;
}

.textFilterFullWidth {
  max-width: 100%;
  width: 100%;
}

.resetFilters {
  @include tablet-up {
    margin-left: $half-spacing;
  }
}