@use 'styles/vars' as *;
@use 'styles/global/text' as *;

.inputContainer {
  position: relative;
  min-width: 12rem;
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.input {
  border: none;

  background-color: transparent;
  padding: $half-spacing 0;
  flex: 1;

  &::placeholder {
    @extend .text-metadata-regular;
  }

  &:focus {
    outline: none;
  }
}

.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $colors-indigo-light;
}

.dropdown {
  position: absolute;
  padding: $half-spacing;
  min-width: 20rem;
  min-height: $content-spacing * 3;
  z-index: 2;
  border-bottom: 0 !important;
  max-height: 40vh;
  overflow-y: auto;
  top: 42px;

  @include mobile-down() {
    max-height: 70vh;
  }
}

.user {
  width: 100%;
  display: block;
  padding: $quarter-spacing $half-spacing;
  outline: none;
  transition: background-color $transition-time-shortest $transition-hover;

  &:focus,
  &:hover {
    background-color: $colors-grey-medium;
  }
}