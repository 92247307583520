@use 'styles/vars' as *;

.previewHeader {
  line-height: 1.4;

  @include clamp-lines(2, 1.4);
}

.previewDescription {
  line-height: 1.4;

  @include clamp-lines(4, 1.4);

  @include mobile-down() {
    @include clamp-lines(3, 1.4);
  }
}