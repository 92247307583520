@use 'styles/vars' as *;
@use 'styles/global/text' as *;
@use 'sass:color';


.companyItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $quarter-spacing * 1.3 $section-spacing;
  font-family: $ff-text;
  font-size: $fs-medium;
  color: color.adjust($colors-grey-medium, $lightness: -10%);
  transition: all $transition-time $transition-hover;

  .logoAndText {
    display: flex;
    align-items: center;
    width: 290px;

    .companyItemName {
      margin-left: $content-spacing;
    }
  }

  &:hover {
    background-color: rgba(color.adjust($colors-indigo-light, $lightness: -7%), 0.6);
    padding-right: $content-spacing * 1.5;
    color: $colors-white;
  }
}

.categoryTitle {
  font-weight: $fw-medium;
  padding: 0 $section-spacing;
}

.showMore {
  color: $colors-white;
  margin: 0 $section-spacing;
}

.drawer {
  position: fixed;
  top: 0;
  left: 80px;
  visibility: hidden;
  width: 0;
  transition: all 0.2s ease-out;
  background-color: $colors-indigo-very-light;
  color: $colors-white;
  z-index: 1200;
  height: 100%;
  overflow-y: auto;
  max-width: 410px;

  @include scrollbar();
}

.drawerOpen {
  visibility: visible;
  transition: width 0.2s ease-out;
  width: 410px;
  outline: none;

  @include desktop-down() {
    width: 50%;
  }
}