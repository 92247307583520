@use 'styles/vars';
@use 'styles/global/text';


.centerItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.attachedFilesHeader {
  padding-bottom: vars.$half-spacing;
  font-weight: text.$fw-medium;
  font-family: text.$ff-display;
  font-size: text.$fs-medium;
}