@use 'styles/vars';

.container {
  display: grid;

  @include vars.mobile-up() {
    grid-template-columns: minmax(200px, 350px) minmax(200px, 350px);
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-flow: column;
  }
}