@use 'styles/vars' as *;

.pill {
  display: inline-block;
  width: 4px;
  height: 13px;
  border-radius: 2px;
  margin-right: $half-spacing;
}

.green {
  background-color: $colors-green;
}

.blue {
  background-color: $colors-blue;
}

.yellow {
  background-color: $colors-yellow;
}

.purple {
  background-color: $colors-purple;
}

.red {
  background-color: $colors-red;
}