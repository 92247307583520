@use 'styles/vars' as *;
@use 'styles/global/text' as *;

.container {
  position: relative;
  padding: $half-spacing;
  background-color: inherit;
  max-width: 100%;
  min-width: 50px;
  min-height: 47px; // Prevent height from varying with different icons/loader

  &:hover {
    background-color: $colors-white;
  }
}

.status {
  position: absolute;
  top: -0.8rem;
  right: -0.78rem;
  background-color: $colors-white;
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
}

.nameAndIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-wrap: break-word;
  max-width: 100%;
  transition: color $transition-time-short $transition-ease-in-out;
}

.nameAndExtension {
  display: grid;
  text-align: left;
  font-size: $fs-small;
  margin-right: $half-spacing;
}

.downloadable {
  color: $colors-indigo;
  cursor: pointer;

  &:hover {
    color: $colors-blue;
  }
}

.icon {
  text-align: left;

  svg {
    font-size: $fs-x-large;
  }
}

.whiteBackground {
  background-color: $colors-white;
}

.fullWidth {
  width: 100%;
  justify-content: flex-start;
  padding: $half-spacing $section-spacing;

  @include desktop-down() {
    padding: $half-spacing $content-spacing;
  }
}

.border {
  border: 1px solid $colors-grey-medium;
  border-radius: $default-border-radius;
}