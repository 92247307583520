@use 'styles/vars' as *;

.cardStack {
  background-color: $colors-grey-light;
  border-radius: $default-border-radius;

  .card {
    border-radius: 0;
    margin-bottom: 1px;

    &.rounded {
      // the main purpose of this class is to make all cards in the cardStack rounded, instead of just the first and last
      border-radius: 6px;

      &:not(:first-of-type) {
        margin-top: 3px;
      }
    }
  }

  > :first-child.card:not(.rounded),
  > :first-child .card:not(.rounded) {
    border-radius: $default-border-radius $default-border-radius 0 0;
  }

  > :last-of-type.card:not(.rounded),
  > :last-of-type .card:not(.rounded) {
    border-radius: 0 0 $default-border-radius $default-border-radius;
    margin-bottom: 0;
  }

  // Support for only child
  > :last-child:first-child.card:not(.rounded),
  > :last-child:first-child .card:not(.rounded) {
    border-radius: $default-border-radius;
  }

  &.white {
    background-color: $colors-white;
  }
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.hoverGrey {
  &:hover {
    background-color: $colors-grey-very-light;
  }
}

.hoverDropShadow {
  transition: all 0.2s;

  &:hover {
    filter: drop-shadow($boxShadow-hover);
  }
}

.cardHeading {
  display: flex;
  align-items: center;

  h5 {
    display: inline-block;
    margin: 0;
  }
}

.paddingDefault {
  padding: $content-spacing;
}

.paddingHalf {
  padding: $half-spacing;
}