@use 'styles/vars' as *;
@use 'styles/global/text' as *;

.formRow {
  margin-top: 0;
  width: 100%;

  >div {
    margin-top: $half-spacing;
  }

  @include mobile-up() {
    margin-top: $half-spacing;
    display: flex;
    gap: $content-spacing;

    >div {
      margin-top: unset;
    }
  }
}

.profileSidebar {
  position: -webkit-sticky;
  position: sticky;
  top: $content-spacing;
  display: flex;
  flex-direction: column;
  margin-right: $content-spacing;

  @include tablet-down() {
    display: none;
  }

  >button {
    color: $colors-grey;
    line-height: 1.33;
    padding: $quarter-spacing 0;
    margin: $quarter-spacing 0;

    &:focus {
      color: $colors-grey;
    }

    &:hover {
      color: $colors-indigo-dark;
    }

    &.activeTab {
      color: $colors-indigo-dark;
    }
  }
}

.activeRoundText {
  color: $colors-green-medium;
}

.closingDetailField {
  display: flex;
  width: 57%;
  justify-content: space-between;
  @extend .text-weight-medium;

  @include mobile-down {
    width: 100%;
  }
}

.closeRoundDetails {
  padding-top: $quarter-spacing;
  background-color: initial;
  transition: all 700ms;

  &.highlighted {
    background-color: $colors-grey-medium;
    transition: all 0s;
  }
}