@use 'styles/vars';


.filterRow {
  display: flex;
  flex-direction: column;

  @include vars.xl-up() {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.filter {
  flex: 1 1 0;
  margin-bottom: vars.$half-spacing;

  @include vars.xl-up() {
    &:nth-of-type(odd) {
      margin-top: 0;
      margin-right: vars.$content-spacing;
    }
  }
}