@use 'styles/vars' as *;

.card {
  overflow: hidden;
  position: relative;
  transition: all 0.2s;

  a {
    outline: none;
  }
}

$drop-shadow-offset: 4rem;

.greyBox {
  background-color: $colors-grey-very-light;
  margin: -1.25rem -1.25rem $drop-shadow-offset * -1 -1.25rem;
  padding: 2.75rem;
}

.avatarContainer {
  display: flex;
  justify-content: center;
  margin: $drop-shadow-offset -1.25rem -6rem;
  padding: 1.25rem;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 0px $half-spacing -2px;
  border-radius: 4px;
}

.avatar {
  position: absolute;
  top: 34px; // transiton from gray to hvite background should be middle of avatar
}

$avatar-height-offset: 3rem;

.body {
  height: 16rem;
  background-color: $colors-white;
  margin: $drop-shadow-offset -1.25rem 0 -1.25rem;
  padding: $avatar-height-offset 1.25rem 0 1.25rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}