@use 'styles/vars' as *;

.buttonList {
  display: flex;
  align-items: center;
  gap: $quarter-spacing;
}

.actionsRight {
  text-align: right;
  justify-content: flex-end;
}

.actionsLeft {
  justify-content: flex-start;
}

.actionsCenter {
  justify-content: center;
}

.vertical {
  flex-direction: column;

  &.actionsCenter {
    align-items: center;
  }

  &.actionsLeft {
    align-items: flex-start;
  }

  .actionsRight {
    text-align: right;
    align-items: flex-end;
  }
}

.white {
  background-color: $colors-white;
}