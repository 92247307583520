@use 'styles/vars' as *;

.bannerHeader {
  display: none;

  @include tablet-up() {
    height: $navbar-logo-height;
    background-color: $colors-indigo-light;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: $content-spacing;
  }
}

.innerBannerHeader {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.bottomMobileNavigationOffset {

  // because the bottom mobile navigation is rendered before the page footer,
  // it will lay on top of it. This offset makes sure the page footer is visible
  @include mobile-down() {
    padding-bottom: 62px; // 62px is the height of the bottom mobile navigation
  }
}