@use 'styles/vars' as *;
@use 'styles/global/text' as *;
@use 'styles/mixins/rich-text' as *;
@use 'styles/functions' as *;

.bannerImage {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  overflow: hidden;
  margin-bottom: $content-spacing;
  max-width: 1400px;
  padding-bottom: 26%;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include mobile-up() {
    flex-direction: row;
  }
}

.actionText {
  font-family: $ff-text;
  font-size: $fs-medium;
  font-weight: $fw-regular;
}

.centerItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagePreviewGrid {
  display: grid;
  background-color: $colors-grey-light;
  width: 100%;
  max-height: 480px;
}

.imagePreviewGridSingle {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.imagePreviewGridDouble {
  grid-template-columns: 1fr 1fr;
  grid-gap: $quarter-spacing;
}

.imagePreviewGridTriple {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(240px, 1fr) minmax(240px, 1fr);
  grid-gap: $quarter-spacing;
}

.verticalImage {
  grid-row: span 2;
  /* Only for 3 images layout */
}

.imagePreviewImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}