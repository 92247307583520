@use 'styles/vars';
@use 'styles/global/text' as *;

div.imageContainer {
  display: flex;
  align-items: center;
}

.currentWork {
  @extend .text-font-display;
}

.location {
  @extend .text-font-display;
  color: vars.$colors-grey;
}