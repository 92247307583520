@use 'styles/vars' as *;
@use 'styles/global/text' as *;

.contactDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: $content-spacing;
  position: relative;
  min-width: 170px;

  svg {
    font-size: $fs-medium;
  }

  a {
    font-size: $fs-tiny;
    line-height: $fs-medium;
  }
}

.emailContainer {
  width: 100%;

  button {
    visibility: hidden;
    padding: $quarter-spacing;
    text-transform: uppercase;
    position: absolute;
    right: $half-spacing;
  }

  &:hover {
    button {
      visibility: visible;
    }
  }
}

.contactBlock {
  margin-top: $half-spacing;
  margin-bottom: $content-spacing;
  position: relative;
}

.contactInfo {
  padding-top: $half-spacing;
  padding-bottom: $half-spacing;
  padding-right: $half-spacing;
  width: 100%;
  border-top: 1px solid $colors-grey-medium;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-of-type {
    border-bottom: 1px solid $colors-grey-medium;
  }
}