@use 'styles/vars';
@use 'styles/global/text' as *;

.message {
  display: flex;
  align-items: center;
  margin-top: vars.$content-spacing;

  &:hover {
    cursor: pointer;
  }
}

.messageList {
  max-width: 550px;
  width: 80%;
}

.timestamp {
  @extend .text-metadata;
  font-weight: $fw-regular;
  margin-left: vars.$half-spacing;
}