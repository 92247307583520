@use 'styles/vars' as *;
@use 'styles/global/text' as *;

.accordionSummary {
  &:hover {
    background-color: $colors-grey-very-light;
  }
}

.title {
  margin-right: $half-spacing;
}

.content {
  max-height: 0;
  overflow: hidden;
  transition: all $transition-time $transition-hover;

  &.open {
    max-height: 400px;
    transition: all $transition-time $transition-hover;
  }
}

.linkContainer {
  flex-shrink: 0;
}

.viewMore {
  display: inline-flex;
  align-items: center;

  .viewMoreIcon {
    transition: all $transition-time $transition-hover;

    &.iconOpen {
      transform: rotate(-180deg);
    }
  }
}

.bottomBorder {
  border-bottom: 2px solid $colors-grey-medium;
}

.noBorder {
  border-top: none;
}

.white {
  background-color: $colors-white;

  &:hover {
    background-color: $colors-grey-very-light;
  }
}

.greyLight {
  background-color: $colors-grey-light;
}

.blueVeryLight {
  background-color: $colors-blue-very-light;

  &:hover {
    background-color: $colors-blue-light;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible:not(:active) {
    outline-color: $colors-blue-medium;
    background-color: $colors-blue-very-light;

    outline-width: 2px;
    outline-style: solid;
    -moz-outline-radius: 8px;
  }
}

.headerPaddingSingle {
  padding-left: $content-spacing;
  padding-right: $content-spacing;
  margin: 0;
}

.headerPaddingDouble {
  padding-left: $section-spacing;
  padding-right: $section-spacing;
  margin: 0;

  @include desktop-down() {
    padding-left: $content-spacing;
    padding-right: $content-spacing;
  }
}

.description {
  max-width: 60%;
  margin: $half-spacing $content-spacing 0 0;
  @extend .text-metadata;

  @include desktop-down() {
    max-width: 70%;
  }

  @include tablet-down() {
    max-width: 80%;
  }

  @include mobile-down() {
    max-width: 90%;
  }
}

.settingsSummary {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  button {
    margin-right: $half-spacing;
  }
}