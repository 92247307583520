@use '../vars' as *;
@use '../z-indexes';
@use '../mixins/helpers' as *;

$spacings: (
  'section': $section-spacing,
  'content': $content-spacing,
  'quarter': $quarter-spacing,
  'half': $half-spacing,
  'no': 0,
);

// Generate spacing helper classes in the form:
// u-{$size}-{$prop}-{$direction}
// $size:      section|content|quarter|half
// $prop:      spacing|padding
// $direction: x|y|left|right|top|bottom

$simpleDirections: (
  top,
  right,
  bottom,
  left
);

@mixin cw-spacing-generator($prop, $className, $list) {
  @each $name, $space in $list {
    @each $d in $simpleDirections {
      &-#{$name}-#{$className}-#{$d} {
        #{$prop}-#{$d}: $space;

        &-reset {
          #{$prop}-#{$d}: -$space;
        }
      }
    }

    &-#{$name}-#{$className} {
      #{$prop}: $space;
    }

    &-#{$name}-#{$className}-x {
      #{$prop}-left: $space;
      #{$prop}-right: $space;

      &-reset {
        #{$prop}-left: -$space;
        #{$prop}-right: -$space;
      }
    }

    &-#{$name}-#{$className}-y {
      #{$prop}-top: $space;
      #{$prop}-bottom: $space;

      &-reset {
        #{$prop}-top: -$space;
        #{$prop}-bottom: -$space;
      }
    }
  }
}

.u {
  @include cw-spacing-generator(margin, spacing, $spacings);
  @include cw-spacing-generator(padding, padding, $spacings);

  &-wrap-text {
    word-break: break-all;
  }

  &-align-center {
    @include alignment(center);
  }

  &-align-right {
    @include alignment(right);
  }

  &-align-left {
    @include alignment(left);
  }

  &-align-justify {
    @include alignment(justify);
  }

  &-margin-left-auto {
    margin-left: auto;
  }

  &-margin-top-auto {
    margin-top: auto;
  }

  &-margin-y-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  &-margin-right-auto {
    margin-right: auto;
  }

  &-margin-right-0 {
    margin-right: 0;
  }

  &-margin-x-auto {
    margin-left: auto;
    margin-right: auto;
  }

  &-white-space-pre-line {
    white-space: pre-line;
  }

  &-white-space-nowrap {
    white-space: nowrap;
  }

  &-fullWidth {
    width: 100%;
  }

  &-fullHeight {
    height: 100%;
  }

  &-threeQuarterWidth {
    width: 75%;
  }

  &-halfWidth {
    width: 50%;
  }

  &-veil::after {
    content: '';
    position: fixed;
    display: block;
    background-color: rgba(60, 60, 60, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: z-indexes.$z-veil;
  }

  &-error-text {
    color: $colors-red;
    font-weight: 700;
  }

  &-color {

    @each $color,
    $code in ('red': $colors-red,
      'blue': $colors-blue,
      'orange': $colors-orange,
      'purple': $colors-purple,
      'green': $colors-green,
      'white': $colors-white,
      'text': $colors-indigo,
      'grey': $colors-grey,
      'grey-medium': $colors-grey-medium,
      'light-blue': $colors-blue-very-light ) {
      &-#{$color} {
        color: $code;
      }
    }
  }

  &-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &-desktop-only {
    @include desktop-only();
  }

  &-desktop-down-only {
    @include desktop-down-only();
  }

  &-tablet-up-only {
    @include tablet-up-only();
  }

  &-desktop-up-only {
    @include desktop-up-only();
  }

  &-xl-up-only {
    @include xl-up-only();
  }

  &-tablet-down-only {
    @include tablet-down-only();
  }

  &-mobile-up-only {
    @include mobile-up-only();
  }

  &-mobile-down-only {
    @include mobile-down-only();
  }

  &-break-words {
    @include break-words();
  }

  &-inline-block {
    display: inline-block;
  }

  &-horizontal-divider {
    height: 1px;
    background-color: $colors-grey-light;
  }

  &-relative {
    position: relative;
  }

  &-pseudo-cover-link::after {
    content: '';
    position: absolute;
    @include position(0);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &-fade-in {
    opacity: 0;
    animation: fadeIn 0.5s ease-out forwards;
  }

  &-fade-in--quick {
    opacity: 0;
    animation: fadeIn 0.2s ease-out forwards;
  }
}