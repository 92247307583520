@use 'styles/vars' as *;
@use 'styles/global/text' as *;

.documentUpload {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: $half-spacing auto;

  &:first-child {
    margin-top: 0;
  }
}

.documentUploadInfo {
  margin-left: $half-spacing;
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex-grow: 1;
}

.nameAndIcon {
  display: flex;
  align-items: center;
}

.name {
  display: block;
  font-size: 0.875rem;
  margin-right: $half-spacing;
}

.progressText {
  @extend .text-metadata;
  margin-left: auto;
}

.errorText {
  @extend .text-metadata;
  text-decoration: line-through;
}