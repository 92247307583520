@use 'styles/vars' as *;
@use 'styles/global/text' as *;


.bannerHeader {
  display: none;

  @include tablet-up() {
    height: $navbar-logo-height;
    background-color: $colors-indigo-light;
    width: 100%;
    display: flex;
    padding: $content-spacing 0 $content-spacing $content-spacing;
  }
}

.innerBannerHeader {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  display: flex;
  padding-right: $section-spacing;
}

.breadcrumb {
  display: none;
  justify-self: flex-start;
  @extend .text-h4;
  margin-left: 0;
  color: $colors-white;
  align-items: center;

  @include tablet-up() {
    display: flex;
  }
}