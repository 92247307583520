@use 'styles/vars' as *;

.currencyRangeGrid {
  display: grid;
  grid-template-columns: 82px auto $content-spacing auto;
  grid-template-areas:
    'inputLabel inputLabel inputLabel inputLabel'
    'currency inputFrom dash inputTo';
}

.currencyInputGrid {
  display: grid;
  grid-template-columns: 82px auto;
  grid-template-rows: 1.6rem auto;
  grid-template-areas:
    'inputLabel inputLabel'
    'currency input'
    'helper-text helper-text';

  &.noLabel {
    grid-template-areas:
      'currency input'
      'helper-text helper-text';
  }
}

.currencySelect {
  >div {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: unset;

    >input {
      text-align: center;
    }
  }
}

.textInput {
  >div {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}