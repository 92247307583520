@use 'styles/vars' as *;
@use 'styles/functions' as *;
@use "sass:color";

$ff-display: 'Red Hat Display', 'Avenir Next', Avenir, 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue',
  Helvetica, Arial, 'Lucida Grande', sans-serif;
$ff-text: 'Red Hat Text', 'Avenir Next', Avenir, 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue',
  Helvetica, Arial, 'Lucida Grande', sans-serif;

$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;
$fw-black: 900;

$fs-x-micro: 0.5rem;
$fs-micro: 0.625rem;
$fs-tiny: 0.75rem;
$fs-small: 0.8125rem;
$fs-medium: 0.9375rem;
$fs-large: 1.0625rem;
$fs-x-large: 1.25rem;
$fs-xx-large: 1.375rem;
$fs-jumbo: 2rem;

.text-h1 {
  font-family: $ff-display;
  font-weight: $fw-black;
  font-size: 4.5rem;
  @include line-height('h1');
}

.text-h2 {
  font-family: $ff-display;
  font-weight: $fw-medium;
  font-size: 2rem;
  @include line-height('h2');
}

.text-h3 {
  font-family: $ff-display;
  font-weight: $fw-medium;
  font-size: 1.375rem;
  @include line-height('h3');
}

.text-h4 {
  font-family: $ff-display;
  font-weight: $fw-medium;
  font-size: $fs-large;
  @include line-height('h4');
}

.text-h5 {
  font-family: $ff-display;
  font-weight: $fw-bold;
  font-size: $fs-small;
}

.text-body {
  font-family: $ff-text;
  font-size: $fs-medium;
  @include line-height('body');
  white-space: pre-line;
  word-break: break-word;
}

.text-richtext {
  @extend .text-body;
  white-space: pre-wrap;
}

.text-subheading {
  font-family: $ff-display;
  font-size: $fs-small;
  font-weight: $fw-bold;
  @include line-height('subheading');
  color: $colors-indigo;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.text-subheading-2 {
  font-family: $ff-text;
  font-size: $fs-small;
  font-weight: $fw-medium;
  @include line-height('subheading');
  color: $colors-grey;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.text-subheading-3 {
  font-family: $ff-display;
  font-size: $fs-medium;
  font-weight: $fw-regular;
  @include line-height('subheading');
  color: $colors-grey-dark;
}

.text-grey {
  font-family: $ff-text;
  color: $colors-grey;
}

.text-metadata {
  font-family: $ff-text;
  font-weight: $fw-regular;
  font-size: $fs-small;
  @include line-height('metadata');
  color: $colors-grey;
}

.text-metadata-regular {
  font-family: $ff-text;
  font-weight: $fw-regular;
  font-size: $fs-medium;
  @include line-height('metadata');
  color: $colors-grey;
}

.text-metadata-regular-black {
  font-family: $ff-text;
  font-weight: $fw-regular;
  font-size: $fs-medium;
  @include line-height('metadata');
}

.text-metadata-medium {
  font-family: $ff-text;
  font-weight: $fw-medium;
  font-size: $fs-medium;
  @include line-height('metadata-medium');
}

.text-table-heading {
  font-family: $ff-text;
  font-weight: $fw-medium;
  font-size: $fs-tiny;
  @include line-height('table-heading');
}

@mixin text-link-styles {
  font-family: $ff-text;
  font-size: $fs-medium;
  @include line-height('link');
  font-weight: $fw-medium;
  text-decoration: none;
  word-break: break-word;
  cursor: pointer;
  transition: color $transition-time-shortest $transition-hover;

  svg {
    vertical-align: text-bottom;
  }
}

.text-link {
  color: $colors-blue;
  @include text-link-styles;

  &:focus,
  &:hover {
    color: color.adjust($colors-blue, $lightness: -15%);
  }
}

.text-link-underlined {
  @extend .text-link;
  border-bottom: 2px solid;
  width: max-content;
}

.text-link-light {
  color: color.adjust($colors-blue, $lightness: 20%);
  @include text-link-styles;

  &:focus,
  &:hover {
    color: $colors-blue-medium;
  }
}

.text-link-mc {
  color: #CF4500;

  // #b03b00
  &:focus,
  &:hover {
    color: color.adjust(#CF4500, $lightness: -15%);
  }
}

.text-black-mc {
  color: #000000 !important;

  .MuiPaper-root,
  button[class*="styles---greyLight---"],
  button[class*="styles---indigo---"] {
    color: #000000 !important;
  }
}

.text-link-small {
  @extend .text-link;
  font-family: $ff-display;
  font-weight: $fw-medium;
  font-size: $fs-small;
  @include line-height('link-small');
}

.text-link-dark {
  @extend .text-link;
  color: $colors-indigo;

  &:focus {
    color: $colors-indigo;
  }

  &:hover {
    color: $colors-blue;
  }
}

.text-link-white {
  @extend .text-link;
  color: $colors-white;

  &:focus {
    color: $colors-blue-very-light;
  }

  &:hover {
    color: $colors-blue-very-light;
  }
}

.text-link-h2 {
  font-family: $ff-display;
  font-weight: $fw-bold;
  font-size: 2.188rem;
  @include line-height('h2');
  text-decoration: none;
  color: $colors-blue;
}

.text-link-h3 {
  @extend .text-h3;
  text-decoration: none;
  color: $colors-blue;
}

.text-link-h4 {
  @extend .text-h4;
  text-decoration: none;
  color: $colors-blue;
}

.text-blockquote {
  font-family: $ff-text;
  font-weight: $fw-medium;
  font-size: $fs-x-large;
  color: $colors-blue;
  @include line-height('blockquote');
}

.text-pre {
  font-family: monospace;
  font-size: $fs-medium;
  @include line-height('body');
}

.text-popover {
  font-size: $fs-medium;
  font-family: $ff-display;
  font-weight: $fw-bold;
  @include line-height('popover');
}

.text-uppercase {
  text-transform: uppercase;
}

.u-background--dark .text {

  &-link,
  &-link-small,
  &-link-h2 {
    color: $colors-white;

    &:hover {
      color: $colors-blue-very-light;
    }
  }
}

.text-preview {
  font-size: $fs-small;
  font-weight: $fw-medium;
  line-height: inherit;
}

.text-x-micro {
  font-size: $fs-x-micro;
}

.text-micro {
  font-size: $fs-micro;
}

.text-tiny {
  font-size: $fs-tiny;
}

.text-small {
  font-size: $fs-small;
  line-height: inherit;
}

.text-medium {
  font-size: $fs-medium;
}

.text-large {
  font-size: $fs-large;
}

.text-x-large {
  font-size: $fs-x-large;
}

.text-xx-large {
  font-size: $fs-xx-large;
}

.text-jumbo {
  font-size: $fs-jumbo;
  @include line-height('jumbo');
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.text-weight-regular {
  font-weight: $fw-regular;
}

.text-weight-medium {
  font-weight: $fw-medium;
}

.text-weight-bold {
  font-weight: $fw-bold;
}

.text-weight-black {
  font-weight: $fw-black;
}

.text-font-display {
  font-family: $ff-display;
}

.text-font-text {
  font-family: $ff-text;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-nowrap {
  white-space: nowrap;
}

.text-error {
  font-family: $ff-text;
  font-size: $fs-tiny;
  color: $colors-red;
}