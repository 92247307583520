@use 'styles/vars' as *;

.preview {
  position: relative;
  margin-bottom: $content-spacing;
  overflow: hidden;

  @include mobile-down() {
    margin-bottom: 0;
  }
}

.activeThumbnail {
  outline: 2px solid $colors-indigo-light;
}

.navigateLeft {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  color: $colors-white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  z-index: 1;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($colors-grey-dark, 0.1);
    transition: transform 0.2s ease-in-out;
  }

  .navigateArrow {
    background-color: rgba($colors-indigo, 0.4);
    border-radius: 100%;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease-in-out;
  }

  &.highlighted {
    &::after {
      background-color: rgba($colors-grey-dark, 0.2);
      transform: matrix(1, 0, 0, 1, -5, 0);
    }

    .navigateArrow {
      transform: matrix(1, 0, 0, 1, -5, 0);
      background-color: $colors-indigo;
    }
  }
}

.navigateRight {
  @extend .navigateLeft;
  left: unset;
  right: 0;

  &.highlighted {
    &::after {
      transform: matrix(1, 0, 0, 1, 5, 0);
    }

    .navigateArrow {
      transform: matrix(1, 0, 0, 1, 5, 0);
    }
  }
}


.mainPreview {
  cursor: zoom-in;
}

.hidden {
  visibility: hidden;
}

.mobileCloseButtonWrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
}

.mobileCloseButton {
  background-color: rgba($colors-white, 0.4);
}