@use 'styles/vars';
@use 'styles/global/text';

.userElement {
  cursor: pointer;
  overflow: hidden;

  .userName {
    font-weight: text.$fw-medium;
    @extend .text-body;
  }

  .userRole {
    @extend .text-metadata;
    font-weight: text.$fw-regular;
  }

  .wrapText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}