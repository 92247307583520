@use 'styles/vars' as *;

.progressSteps {
  margin-top: 7px; // Align with top of h3 (doesn't go all the way up because of line-height)
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  margin-left: $half-spacing;
}

.progressStep {
  height: 4px;
  width: 4px;
  background-color: $colors-grey;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.progressStep.isActive {
  width: 24px;
  background-color: $colors-blue;
  transition: width $transition-time-short $transition-ease-in-out 0ms;
}


.progressStep.isComplete {
  background-color: $colors-blue;
}