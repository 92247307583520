@use 'sass:math';
@use 'styles/vars' as *;
@use 'styles/z-indexes';
@use 'styles/global/text' as *;

.dropzoneContainer {
  position: relative;
  width: fit-content;
}

.dropzone {
  cursor: pointer;
  overflow: hidden;
  position: relative;

  &>canvas {
    width: 100%;
    height: 100%;
  }
}

.dropzone.dropped {
  z-index: z-indexes.$z-veil-focus;
}

.toolbar {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  min-width: 410px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  color: $colors-white;
  background-color: $colors-white;
  z-index: z-indexes.$z-layer-6;
  box-shadow: 0 2px 2px rgba(60, 60, 60, 0.3);
}

.toolbar::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border-top: solid 10px $colors-white;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
  z-index: z-indexes.$z-overpowered;
}

.action {
  margin-right: $half-spacing;

  &:last-child {
    margin-right: 0;
  }
}

.dropzoneHover {
  &:hover::after {
    content: 'Change';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: $font-root-size;
    color: $colors-white;
    background-color: rgba(60, 60, 60, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.round {
    &:hover::after {
      border-radius: 50%;
    }
  }
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  $h: 1rem;
  height: $h;
  border-radius: math.div($h, 2);
  background: $colors-grey-light;
  outline: none;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: $content-spacing;
  border: 2px dashed $colors-blue;
  color: $colors-blue;
  font-style: italic;
  font-size: 0.875rem;
  line-height: 1.5;

  @include mobile-down() {
    font-size: $fs-small;
  }
}

.round {
  border-radius: 50%;
}

.horizontalLayout {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: $section-spacing;
  row-gap: $content-spacing;


  @include tablet-down() {
    flex-direction: column;
    align-items: flex-start;

    &.center {
      align-items: center;
    }
  }

  .dropzone {
    flex-shrink: 0;
  }

  .toolbar {
    top: -10%;
    left: 70px;
    width: unset;
  }


}


.deleteButton {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 10;
}

.editButton {
  position: absolute;
  bottom: -4px;
  left: 10px;
  z-index: 10;
  background-color: $colors-blue;

  @include mobile-down() {
    bottom: 0px;
    left: 15px;
  }

  &:hover {
    background-color: $colors-blue-dark;
    color: $colors-white;
  }
}