@use 'styles/vars' as *;
@use 'styles/z-indexes' as *;
@use 'styles/global/text' as *;
@use "sass:color";

.react-datepicker-popper {
  z-index: $z-layer-2;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  @extend .text-font-display;
  font-size: 0.875rem;
  border-color: $colors-blue;
}

.react-datepicker__year-dropdown {
  background-color: $colors-white;
  border-radius: $default-border-radius;
  border: 1px solid $colors-blue;
  color: $colors-indigo;
}

.react-datepicker__year-option:first-of-type {
  border-top-left-radius: $default-border-radius;
  border-top-right-radius: $default-border-radius;
}

.react-datepicker__year-option:last-of-type {
  border-bottom-left-radius: $default-border-radius;
  border-bottom-right-radius: $default-border-radius;
}

.react-datepicker__navigation--years-upcoming {
  border-bottom-color: $colors-blue;

  &:hover {
    border-bottom-color: $colors-white;
  }
}

.react-datepicker__navigation--years-previous {
  border-top-color: $colors-blue;

  &:hover {
    border-top-color: $colors-white;
  }
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: $colors-white;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: $colors-white;
}

.react-datepicker__year-option:hover {
  background-color: $colors-blue;
  color: $colors-white;
}

.react-datepicker__header {
  background-color: $colors-blue;
  color: $colors-white;
  border-bottom: none;
  border-top-right-radius: $default-border-radius;
  border-top-left-radius: $default-border-radius;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
  color: $colors-white;
  font-weight: 500;
}

.react-datepicker__navigation--next {
  border-left-color: $colors-white;

  &:hover {
    border-left-color: color.adjust($colors-blue, $lightness: -20%);
  }
}

.react-datepicker__navigation--previous {
  border-right-color: $colors-white;

  &:hover {
    border-right-color: color.adjust($colors-blue, $lightness: -20%);
  }
}

.react-datepicker__year-read-view--down-arrow {
  border-top-color: $colors-white;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  border-bottom-color: $colors-blue;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
  border-top-color: $colors-blue;
}

.react-datepicker-popper[data-placement^='left'] .react-datepicker__triangle {
  border-left-color: $colors-blue;
}

.react-datepicker-popper[data-placement^='right'] .react-datepicker__triangle {
  border-right-color: $colors-blue;
}

.react-datepicker__day {
  color: $colors-indigo;
}

.react-datepicker__day--disabled {
  color: $colors-grey;
}

.react-datepicker__day:hover {
  border-radius: $default-border-radius;
}

.react-datepicker__day--selected {
  color: $colors-white;
  background-color: $colors-blue;
  border-radius: $default-border-radius;
  transition: background-color $transition-time;

  &:hover {
    background-color: color.adjust($colors-blue, $lightness: -10%);
  }
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow {
  border-top-color: color.adjust($colors-blue, $lightness: -20%);
}

.react-datepicker__input-container {
  display: block;
}