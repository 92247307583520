.unfollowButton:hover {
  &>* {
    opacity: 0;
  }

  &:after {
    position: absolute;
    width: 100%;
    content: 'Unfollow';
  }
}

.pendingButton:not(:hover) {
  opacity: 0.6;
}

.pendingButton:hover {
  &>* {
    visibility: hidden;
  }

  &:after {
    position: absolute;
    width: 100%;
    content: 'Withdraw';
  }
}

.pendingButton.largeButton:hover {
  &:after {
    top: 10px;
  }
}