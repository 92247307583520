@use 'styles/vars' as *;
@use 'styles/global/text' as *;
@use 'styles/z-indexes' as *;
@use 'styles/mixins/helpers';


$color-sidebar-background-color: $colors-indigo-dark;
$color-sidebar-menu-item: $colors-white;
$border-sidebar-separator: 2px solid $colors-indigo-light;
$nav-link-opacity: 0.6;
$nav-link-opacity-transition-time: 0.1s;
$nav-width: 80px;

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: $nav-link-opacity;
    transform: translateY(0);
  }
}

.navigation {
  flex: 0 0 auto;
  width: $nav-width;
  display: flex;
  flex-direction: column;
  background-color: $color-sidebar-background-color;
  font-family: $ff-text;
  font-weight: $fw-medium;
  font-size: $fs-micro;
  color: $colors-white;
  letter-spacing: 0.01em;
}

.inner {
  position: fixed;
  flex: 0 0 auto;
  width: $nav-width;
  display: flex;
  flex-direction: column;
  background-color: $color-sidebar-background-color;
  height: 100vh;
  z-index: $z-layer-1;
}

.logoContainer {
  border-bottom: $border-sidebar-separator;
}

.logoWrapper {
  padding: 15px 0 10px 0;
  height: 78px;
  color: $colors-white;
  display: flex;
  justify-content: center;
}

.cwLogo {
  opacity: $nav-link-opacity;
  transition: opacity $nav-link-opacity-transition-time ease;

  &.selected,
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.companyLogoWrapper {
  background: $colors-white;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid $colors-grey-disabled;
}

.links {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: $half-spacing;
  @include scrollbar();
}

.icon {
  max-width: 29px;
  max-height: 29px;
}

.link {
  animation: slide-up $transition-time ease;
  text-decoration: none;
  text-align: center;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: $color-sidebar-menu-item;
  opacity: $nav-link-opacity;
  transition: opacity $nav-link-opacity-transition-time ease;

  p {
    margin-top: 4px;
  }

  &.small {
    padding: 6px 5px;

    &>p {
      margin-top: 1px;
    }
  }

  .avatar {
    margin: 0 25px;
  }

  &.selected,
  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: 0;
  }
}

.openNavbarIcon {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: 0px;
    top: 31px;
    background-color: $colors-indigo;
    width: 0;
    height: 0;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-right: 7px solid $colors-indigo-very-light;
  }
}

.userName {
  @include break-words();
}

.reverseLink {
  @extend .link;

  &:not(:last-of-type) {
    border-bottom: $border-sidebar-separator;
  }
}

.inContextLinks {
  background-color: $colors-indigo-light;
  margin: $half-spacing 0;
}

.overlayButton {
  display: block;
  background-color: $colors-white;
  border-radius: 50%;
  box-shadow: 1px 1px 4px rgba(20, 20, 20, 0.4);
  padding: $eighth-spacing;
  @include helpers.increase-hitbox();

  svg {
    transition: all $nav-link-opacity-transition-time ease-in-out;
  }

  &:hover {
    animation-name: hover;
    animation-duration: $transition-time;
    animation-fill-mode: forwards;

    svg {
      stroke: $colors-white;
    }
  }
}

@keyframes hover {
  100% {
    background-color: $colors-blue;
  }
}