@use 'styles/vars';
@use 'styles/functions' as *;
@use 'styles/global/text' as *;

.accordion {
  margin-bottom: vars.$half-spacing;
  min-width: 320px;
}

.shortlistCompanyMission {
  @extend .text-body;
  @include vars.clamp-lines(3, getLineHeight('body'));
  min-height: 5.01rem;
  margin: vars.$half-spacing 0;
}