@use 'styles/vars' as *;
@use 'styles/global/text' as *;

.responseFormIndent {
  margin-left: 3rem;
}

.editor {
  flex-grow: 1;
  outline: 1px solid $colors-grey-disabled;
  border-radius: $default-border-radius;

  >pre {
    >div {
      min-height: unset;
      max-height: 260px;
      padding: $half-spacing;

      &:focus {
        outline: 1px solid $colors-grey;
        border-radius: $default-border-radius;
      }
    }
  }
}

.messageFormButtons {
  align-self: flex-end;
  display: flex;
  align-items: center;
}

.submitButton {
  @include mobile-down() {
    min-width: initial;
    padding: $half-spacing;
  }
}

.comment {
  font-size: $fs-medium;
  line-height: 1.5;
}

.commentHighlight {
  animation: fadeOut 2s forwards;
}

@keyframes fadeOut {
  0% {
    background-color: $colors-yellow-light;
  }

  30% {
    background-color: $colors-yellow-light;
  }

  100% {
    background-color: inherit;
  }
}