@use 'styles/vars' as *;

.upcomingSteps {
  display: flex;
  justify-content: space-around;
}

.upcomingStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.upcomingStepIcon {
  height: 36px;
  width: 36px;
  color: $colors-blue;
  background-color: $colors-blue-very-light;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  svg {
    font-size: 1.25rem;
  }
}

.upcomingSection {
  margin-top: 16px;
  display: flex;
  justify-content: space-around;
  flex: 1;

  &>div {
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: $colors-grey-medium;
  }

}