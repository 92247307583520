@use 'styles/vars' as *;
@use 'styles/functions' as *;
@use 'styles/z-indexes';

[id] {

  // offsets #hash links scrolls to account for fixed header
  @include tablet-down() {
    scroll-margin-top: $mobile-navbar-height;
  }
}

.header {
  display: flex;
  align-items: center;
  align-content: center;
  background-color: $colors-indigo;
  height: $mobile-navbar-height;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: z-indexes.$z-layer-3;
  justify-content: space-between;
}

.mobileTitle {
  color: $colors-white;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
}

.menu {
  color: $colors-white;
  font-size: 38px;
}

.titleWrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}