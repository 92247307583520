@use 'styles/vars' as *;
@use 'styles/global/text' as *;
@use "sass:color";

.infoMessage {
  @extend .text-body;
  background-color: $colors-blue-pale;
  color: $colors-white;
  display: flex;
  text-align: left;
  font-family: $ff-display;
  font-size: $fs-medium;
  font-weight: $fw-medium;
  align-items: center;
  line-height: 1.3em;
  border-radius: $default-border-radius;
  padding: $content-spacing;

  .title {
    font-family: $ff-display;
    font-weight: $fw-bold;
    font-size: $fs-tiny;
    text-transform: uppercase;
    color: rgba($colors-white, 0.5);
  }

  &>div:not(.iconWrapper) {
    flex: 1;
  }

  &Yellow {
    background-color: $colors-yellow-medium;
    color: $colors-indigo;
  }

  &Indigo {
    background-color: $colors-indigo;
    color: $colors-white;
  }

  &White {
    background-color: $colors-white;
    color: $colors-indigo;
  }

  &BlueLight {
    background-color: $colors-blue-very-light;
    color: $colors-indigo;

    .title {
      color: rgba($colors-grey, 0.75);
    }
  }

}

.columnLayout {
  flex-direction: column;
  align-items: baseline;
  gap: $content-spacing;
}

.iconWrapper {
  height: $section-spacing;
  width: $section-spacing;
  border-radius: 50%;
  background-color: rgba($colors-white, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: $content-spacing;

  &.columnLayout {
    @include mobile-up() {
      margin-left: $content-spacing;
      margin-right: $section-spacing;
    }
  }

  svg {
    font-size: $content-spacing;
  }

  &Yellow {
    background-color: rgba($colors-yellow-light, 0.5);
  }

  &White {
    color: $colors-blue;
    background-color: $colors-grey-light;
  }

  &BlueLight {
    background-color: rgba($colors-blue-light, 0.5);
  }
}

.infoMessageInline {
  border-radius: $default-border-radius;
  display: flex;
  color: $colors-indigo-light;
  text-align: left;
  font-family: $ff-display;
  font-size: $fs-small;
  font-weight: $fw-medium;
  align-items: center;
  line-height: 1.3em;

  p {
    font-family: $ff-display;
  }


  a {
    font-family: $ff-display;
    text-decoration: underline;
    transition: text-decoration 0.3s ease-out;

  }
}

.iconWrapperInline {
  margin-right: $half-spacing;

  svg {
    font-size: $content-spacing;
  }
}

.colorBlue {
  color: $colors-blue;
  background-color: color.adjust($colors-blue-light, $lightness: 12%);
}