@use 'styles/vars';

.portrait {
  border-radius: vars.$default-border-radius;
}

.avatars {
  position: relative;
}

.companyAvatar {
  background-color: vars.$colors-grey-medium;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  svg {
    opacity: 50%;
  }
}