@use 'styles/vars' as *;

.uploadedAttachments {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: $content-spacing;
  padding-left: $half-spacing;
  padding-bottom: $half-spacing;
  padding-right: 0.9rem; // Make sure attachments don't stick out. Not an exact science when width is in %
  width: 100%;
  overflow: hidden;
  pointer-events: none;

  @include mobile-down() {
    padding-right: 1.8rem;
  }
}

.messageFormButtons {
  margin-left: $quarter-spacing;
  align-self: flex-end;
  display: flex;
}

.submitButton {
  @include mobile-down() {
    min-width: initial;
    padding: $half-spacing;
  }
}

.characterCounter {
  position: absolute;
  bottom: 9px;
  overflow: hidden;
  pointer-events: none;
  right: 50px;

  @include mobile-down() {
    right: 40px;
  }
}

.attachmentIcon {
  position: absolute;
  bottom: $half-spacing;
  right: $content-spacing;
  pointer-events: auto;

  @include mobile-down() {
    right: $half-spacing;
  }
}

.textField {
  background-color: $colors-white;
  border-radius: 4px;

  &.pending {
    background-color: $colors-grey-light;
  }
}