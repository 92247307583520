.colorDot {
  width: 12px;
  height: 12px;
  border-radius: 6px;
}

.colorDotTiny {
  width: 6px;
  height: 6px;
  border-radius: 6px;
}

.colorDotLarge {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
