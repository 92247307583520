@use 'styles/vars' as *;
@use 'styles/global/text';
@use 'sass:list';

@mixin quillContent {
  $rich-text-max-width: 50rem;

  > :first-child {
    margin-top: 0;
  }

  h1 {
    display: block;
    @extend .text-body;
    @extend .text-weight-medium;
    @extend .text-x-large;
    margin: text.$fs-medium 0;

    a {
      @extend .text-link;
      @extend .text-x-large;
      @include break-words();
    }
  }

  h2 {
    display: block;
    @extend .text-body;
    font-weight: text.$fw-bold;
    margin: $quarter-spacing 0;

    a {
      @extend .text-link;
      @include break-words();
    }
  }

  blockquote {
    @extend .text-blockquote;
    display: block;
    margin: $section-spacing 0 $section-spacing 6%;
    padding: $section-spacing 0 $content-spacing 4%;
    max-width: $rich-text-max-width;
    border-left: 3px solid $colors-blue !important;
    position: relative;

    &::before {
      content: url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%3C!--%20Generator%3A%20Adobe%20Illustrator%2019.0.0%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200)%20%20--%3E%3C!--%20License%3A%20CC0.%20Made%20by%20SVG%20Repo%3A%20https%3A%2F%2Fwww.svgrepo.com%2Fsvg%2F128449%2Fleaf%20--%3E%3Csvg%20width%3D%2212%22%20height%3D%229%22%20viewBox%3D%220%200%2012%209%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M11.291%200.687635L11.971%201.87764C11.359%202.0363%2010.8037%202.35364%2010.305%202.82964C9.82903%203.28297%209.59103%203.82697%209.59103%204.46164C9.59103%205.0963%209.76103%205.5383%2010.101%205.78764C10.4637%206.0143%2010.9964%206.12764%2011.699%206.12764V6.97764C11.699%207.4763%2011.5744%207.9183%2011.325%208.30364C11.0757%208.6663%2010.611%208.84764%209.93103%208.84764C8.84303%208.84764%208.06103%208.4963%207.58503%207.79364C7.10903%207.09097%206.87103%206.30897%206.87103%205.44763C6.87103%204.49564%207.09769%203.69097%207.55103%203.03364C8.00436%202.35364%208.57103%201.82097%209.25103%201.43564C9.95369%201.02764%2010.6337%200.778302%2011.291%200.687635ZM5.17103%200.687635L5.85103%201.87764C5.23903%202.0363%204.68369%202.35364%204.18503%202.82964C3.70903%203.28297%203.47103%203.82697%203.47103%204.46164C3.47103%205.0963%203.64103%205.5383%203.98103%205.78764C4.34369%206.0143%204.87636%206.12764%205.57903%206.12764V6.97764C5.57903%207.4763%205.45436%207.9183%205.20503%208.30364C4.95569%208.6663%204.49103%208.84764%203.81103%208.84764C2.72303%208.84764%201.94103%208.4963%201.46503%207.79364C0.989025%207.09097%200.751025%206.30897%200.751025%205.44763C0.751025%204.49564%200.977692%203.69097%201.43103%203.03364C1.88436%202.35364%202.45103%201.82097%203.13103%201.43564C3.83369%201.02764%204.51369%200.778302%205.17103%200.687635Z%22%20fill%3D%22%23346AFF%22%2F%3E%3C%2Fsvg%3E');
      position: absolute;
      top: -$content-spacing;
      left: 4.5%;
      font-size: 3rem;
      color: $colors-blue;
    }

    a {
      @extend .text-link-underlined;
      @extend .text-blockquote;
    }
  }

  p {
    @extend .text-richtext;
    max-width: $rich-text-max-width;
    line-height: 1.5;

    @include mobile-down {
      line-height: 1.4;
    }
  }

  p:not([class^='ql-custom-paragraph']) {
    margin-bottom: $content-spacing;
  }

  p:first-child {
    margin-top: 0;
  }

  p b,
  p strong,
  li b,
  li strong {
    @extend .text-richtext;
    font-weight: text.$fw-medium;

    line-height: 1.5;

    @include mobile-down {
      line-height: 1.4;
    }
  }

  pre {
    @extend .text-pre;
  }

  pre:focus {
    outline: none;
  }

  figure {
    padding-top: $content-spacing;
    padding-bottom: $content-spacing;
    max-width: $rich-text-max-width;
  }

  img {
    max-height: 600px; // 600px supports 16:9 and 4:3 aspect ratios on 800px wide content
    width: auto;
    display: block;
  }

  p a {
    @extend .text-link;
    @include break-words();

    line-height: 1.5;

    @include mobile-down {
      line-height: 1.4;
    }
  }
}

@mixin quillNestedLists {

  ul,
  ol {
    @extend .text-richtext;
    line-height: 1.5;
    margin: 0;
    padding: 0;

    @include mobile-down {
      line-height: 1.4;
    }

    li {
      padding-left: 0;
      margin-left: $content-spacing;
      list-style-position: outside;

      a {
        @extend .text-link;
      }

      &::before {
        content: none;
      }
    }
  }

  ul li:not([class^='ql-indent-']) {
    list-style-type: disc;
  }

  ol {
    $levels: (
    );

  @for $i from 0 through 8 {
    $levels: list.append($levels, level-#{$i});
  }

  counter-reset: $levels;
  list-style-type: none;
}

ol li:not([class^='ql-indent-']) {
  counter-increment: level-0;
}

ol li::before {
  content: counter(level-0) '. ';
  text-align: left;
  margin: 0;
}

@for $i from 0 through 7 {
  li.ql-indent-#{$i + 1} {
    margin-left: 1rem * ($i + 1);
    padding-left: 0;
  }

  @include mobile-up() {
    li.ql-indent-#{$i + 1} {
      margin-left: 3rem * ($i + 1);
    }
  }

  ul li.ql-indent-#{$i + 1} {
    list-style-type: list.nth((circle, square, disc), ($i % 3) + 1);
  }

  ol li.ql-indent-#{$i + 1} {
    counter-increment: level-#{$i + 1};

    &::before {
      content: counter(level-#{$i + 1}) '. ';
    }
  }
}
}