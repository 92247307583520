@use 'styles/vars';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.heading {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;

  >* {
    margin-left: vars.$half-spacing;
  }
}