@use '../vars' as *;

.u {
  &-flex {
    display: flex;
  }

  &-inline-flex {
    display: inline-flex;
  }

  &-flex-grow {
    flex-grow: 1;
  }

  &-flex-shrink-0 {
    flex-shrink: 0;
  }

  &-flex-end {
    display: flex;
    justify-content: flex-end;
  }

  &-flex-center {
    display: flex;
    justify-content: center;
  }

  &-flex-space-evenly {
    display: flex;
    justify-content: space-evenly;
  }

  &-flex-space-around {
    display: flex;
    justify-content: space-around;
  }

  &-flex-space-between {
    display: flex;
    justify-content: space-between;
  }

  &-flex-align-center {
    display: flex;
    align-items: center;
  }

  &-flex-align-baseline {
    display: flex;
    align-items: baseline;
  }

  &-flex-align-stretch {
    display: flex;
    align-items: stretch;
  }

  &-flex--align-items-start {
    align-items: start;
  }

  &-flex--align-items-center {
    align-items: center;
  }

  &-flex--align-items-end {
    align-items: flex-end;
  }

  &-flex--column {
    flex-direction: column;
  }

  &-flex--column-reverse {
    flex-direction: column-reverse;
  }

  &-flex--row {
    flex-direction: row;
  }

  &-flex--row-reverse {
    flex-direction: row-reverse;
  }

  &-flex--aSelf-start {
    align-self: flex-start;
  }

  &-flex--aSelf-end {
    align-self: flex-end;
  }

  &-flex--aSelf-center {
    align-self: center;
  }

  &-flex--jSelf-end {
    justify-self: flex-end;
  }

  &-flex--jSelf-start {
    justify-self: flex-start;
  }

  &-flex--start {
    justify-content: flex-start;
  }

  &-flex-1 {
    flex: 1;
  }

  &-flex--wrap {
    flex-wrap: wrap;
  }

  &-flex-column-1 {
    width: 100%;
  }

  &-flex-column-2 {
    width: 50%;
    max-width: 50%;
    flex-grow: 1;
  }

  &-flex--gap-quarter {
    gap: $quarter-spacing;
  }

  &-flex--gap-half {
    gap: $half-spacing;
  }

  &-flex--gap-1 {
    gap: $content-spacing;
  }

  &-flex--gap-2 {
    gap: $section-spacing;
  }
}