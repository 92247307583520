@use 'styles/vars' as *;
@use 'styles/global/text' as *;

.percentage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: $colors-green;
  @extend .text-subheading;
  font-size: $fs-large;
  display: flex;
  align-items: center;
  justify-content: center;

  &.textSmall {
    font-size: $fs-micro;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}