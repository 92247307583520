@use 'styles/vars';
@use 'styles/global/text';


.horizontalValue {
  font-size: text.$fs-medium;
  color: vars.$colors-indigo-light;

  a {
    font-size: inherit;
  }
}