@use 'styles/vars';

.document {
  transition: all vars.$transition-time vars.$transition-hover;

  &:hover {
    color: vars.$colors-blue;
  }
}

.listItem {
  margin-bottom: vars.$half-spacing;
}

.listItem:last-of-type {
  margin-bottom: 0;
}