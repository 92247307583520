@use 'styles/vars' as *;
@use 'styles/global/text' as *;
@use "sass:color";

.button {
  @extend .text-font-text;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: $default-border-radius;
  font-weight: 500;
  line-height: 1.38;
  border: 1px solid transparent;
  transition:
    background-color $transition-time-short $transition-ease-in-out 0ms,
    border $transition-time-short $transition-ease-in-out 0ms,
    color $transition-time-short $transition-ease-in-out 0ms,
    opacity $transition-time-short $transition-ease-in-out 0ms;
  position: relative;

  &:disabled {
    opacity: 0.6;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    z-index: 10; // Make sure outline is visible
    outline-color: $colors-blue-medium;
    outline-width: 2px;
    outline-style: solid;
    -moz-outline-radius: 8px;
  }

  &.success {
    @extend .green;
    opacity: 1;
  }

  svg {
    margin-top: -8px; // Make sure icons don't make the button grow
    margin-bottom: -8px;
  }
}

.rounded {
  border-radius: 50px;
}

.isLoading {
  visibility: hidden;
}

.spinner {
  position: absolute;
  margin-left: -8px;
  left: 50%;
  margin-top: -8px;
  top: 50%;

  svg {
    margin: 0;
  }
}

.small {
  padding: 7px 13px;
  font-size: $fs-tiny;
  min-width: 50px;
}

.medium {
  padding: 10px 19px;
  font-size: $fs-tiny;
  min-width: 67px;
}

.large {
  padding: $half-spacing $half-spacing * 3;
  min-width: 100px;
  font-size: $fs-medium;
}

.blue {
  color: $colors-blue;
}

.blueLight {
  color: $colors-blue-very-light;
}

.red {
  color: $colors-red;
}

.orange {
  color: $colors-orange;
}

.yellow {
  color: $colors-yellow;
}

.green {
  color: $colors-green;
}

.greenDark {
  color: $colors-green;
}

.indigo {
  color: $colors-indigo;
}

.white {
  color: $colors-white;
}

.grey {
  color: $colors-grey-medium;
}

.greyLight {
  color: $colors-grey-light;
}

.primary {
  color: $colors-white;

  &:disabled {
    color: $colors-grey-light;

    &:focus-visible {
      outline: none;
    }
  }

  &.blue {
    background-color: $colors-blue;

    &.inactive {
      color: $colors-grey-medium;
      background-color: $colors-grey-dark;
    }

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: $colors-blue-dark;
    }
  }

  &.blueLight {
    color: $colors-indigo-very-light;
    background-color: $colors-blue-very-light;

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: $colors-blue-light;
    }
  }

  &.red {
    background-color: $colors-red;

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: $colors-red-dark;
    }
  }

  &.orange {
    background-color: $colors-orange;

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: $colors-orange-dark;
    }
  }

  &.yellow {
    background-color: $colors-yellow;

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: $colors-yellow-dark;
    }
  }

  &.purpleLight {
    color: $colors-violet;
    background-color: $colors-purple-light;

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: $colors-purple-medium;
    }
  }

  &.green {
    color: $colors-green-darker;
    background-color: $colors-green;

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: $colors-green-dark;
    }
  }

  &.greenDark {
    color: $colors-grey-very-light;
    background-color: $colors-green-darker;

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: $colors-black;
    }
  }

  &.grey {
    background-color: $colors-grey;

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: $colors-grey-dark;
    }
  }

  &.greyLight {
    color: $colors-indigo;
    background-color: $colors-grey-light;

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: $colors-grey-medium;
    }
  }

  &.indigo {
    background-color: $colors-indigo;

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: $colors-indigo-dark;
    }
  }

  &.white {
    color: $colors-blue;
    background-color: $colors-white;

    &:disabled {
      color: $colors-grey;
      opacity: 1;
    }

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: $colors-grey-light;
    }
  }
}

.secondary {
  &.blue {
    border-color: $colors-blue;

    &:disabled {
      color: $colors-blue-medium;
      border-color: $colors-blue-medium;
      opacity: 1;
    }

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: $colors-blue;
    }
  }

  &.red {
    border-color: $colors-red;

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: $colors-red;
    }
  }

  &.orange {
    border-color: $colors-orange;

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: $colors-orange;
    }
  }

  &.yellow {
    border-color: $colors-yellow;

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: $colors-yellow;
    }
  }

  &.green {
    border-color: $colors-green;

    &:disabled {
      color: $colors-green-medium;
      border-color: $colors-green-medium;
      opacity: 1;
    }

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: $colors-green;
    }
  }

  &.grey {
    color: $colors-grey-dark;
    border-color: $colors-grey-dark;

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      color: $colors-indigo;
      background-color: $colors-white;
      border-color: $colors-indigo;
    }
  }

  &.indigo {
    border-color: $colors-indigo;

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: $colors-indigo;
    }
  }

  &.white {
    border-color: $colors-white;

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      color: $colors-blue;
      background-color: $colors-white;
    }
  }

  &:disabled {
    opacity: 0.6;
    color: $colors-grey;
    border-color: $colors-grey;

    &:focus-visible {
      outline: none;
    }
  }

  &:hover:not(:disabled):not(.subtleHoverStyle) {
    color: $colors-white;
  }
}

.tertiary {
  padding-left: 8px;
  padding-right: 8px;

  &.red {

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: rgba($colors-red, 0.1);
    }
  }

  &.orange {

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: rgba($colors-orange, 0.1);
    }
  }

  &.yellow {

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: rgba($colors-yellow, 0.1);
    }
  }

  &.green {

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: rgba($colors-green, 0.1);
    }
  }

  &.indigo {

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: rgba($colors-indigo, 0.05);
    }
  }

  &.grey {
    color: $colors-grey-dark;

    &:focus-visible:not(:active) {
      outline-color: $colors-grey-medium;
    }

    &.isActive:not(:disabled),
    &:hover:not(:disabled):not(.subtleHoverStyle) {
      background-color: rgba($colors-grey, 0.1);
    }
  }

  &:disabled {
    opacity: 0.6;
    color: $colors-grey;

    &:focus-visible {
      outline: none;
    }
  }

  &.isActive:not(:disabled),
  &:hover:not(:disabled):not(.subtleHoverStyle) {
    background-color: rgba($colors-blue-very-light, 0.5);
  }
}

.subtleHoverStyle:hover {
  transition: all 150ms $transition-hover;

  &.blue {
    border-color: color.adjust($colors-blue, $lightness: -10%);
  }

  &.blueLight {
    border-color: $colors-blue-very-light;
  }

  .red {
    border-color: $colors-red-dark;
  }

  &.orange {
    border-color: $colors-orange-dark;
  }

  &.yellow {
    border-color: $colors-yellow-dark;
  }

  &.green {
    border-color: $colors-green-dark;
  }

  &.greenDark {
    border-color: $colors-green-darker;
  }

  &.indigo {
    border-color: $colors-indigo-dark;
  }

  &.white {
    border-color: $colors-grey;
  }

  &.grey {
    border-color: $colors-indigo;
  }

  &.greyLight {
    border-color: $colors-grey-dark;
  }
}

.subtleHoverStyle:not(.primary):hover {
  &.blue {
    color: color.adjust($colors-blue, $lightness: -10%);
  }

  &.blueLight {
    color: $colors-blue-very-light;
  }

  .red {
    color: $colors-red-dark;
  }

  &.orange {
    color: $colors-orange-dark;
  }

  &.yellow {
    color: $colors-yellow-dark;
  }

  &.green {
    color: $colors-green-dark;
  }

  &.greenDark {
    color: $colors-green-darker;
  }

  &.indigo {
    color: $colors-indigo-dark;
  }

  &.white {
    color: $colors-grey;
  }

  &.grey {
    color: $colors-indigo;
  }

  &.greyLight {
    color: $colors-grey-dark;
  }
}

.disabled {
  pointer-events: none;
  color: $colors-grey;
  opacity: 0.6;
}