@forward 'styles/global/shared';
@use 'styles/global/u' as *;
@use 'styles/vars' as *;
@use 'styles/global/text' as *;

html,
body {
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  background-color: $colors-grey-light;
  @extend .text-font-display;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $colors-indigo;

  ::selection {
    color: $colors-white;
    background-color: $colors-blue;
  }

  & * {
    box-sizing: border-box;
  }
}

#app {
  height: 100%;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
}

hr {
  border: 0;
  border-bottom: 2px solid $colors-grey-light;
  color: transparent;
  margin: 0;
}

h1 {
  margin: $content-spacing 0;
}

p {
  font-family: $ff-text;
  line-height: 1.5;
}