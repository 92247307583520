@use 'styles/vars' as *;

.logoPlaceholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bannerActions {
  display: flex;
  align-items: center;
  margin-top: $content-spacing;

  @include tablet-down() {
    flex-direction: column;
    align-items: flex-start;
    gap: $content-spacing;
  }
}
