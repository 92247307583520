@use 'styles/vars' as *;
@use 'styles/global/text' as *;

.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: $section-spacing;

  @include tablet-down() {
    flex-direction: column;
  }

  .left {
    width: 60%;
    max-width: 820px;
    padding-right: $section-spacing;
    padding-bottom: $section-spacing;
  }

  .right {
    border-radius: $default-border-radius;
    padding: $content-spacing;
    background-color: $colors-grey-light;
    position: relative;
    width: 40%;
  }

  @include tablet-down() {
    .left {
      width: 100%;
      padding-right: 0;
    }

    .right {
      padding: $content-spacing;
      width: auto;

      &::before {
        width: 100%;
        height: 2px;
        top: 0;
      }
    }
  }
}