@use 'styles/vars' as *;
@use 'styles/global/text' as *;


.menuItemList {
  max-height: 250px;
  overflow-y: auto;
  @include scrollbar;

  &::-webkit-scrollbar-track {
    background: initial;
  }
}

.menuItem {
  width: 100%;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background-color: $colors-grey-light;
  }

  &.disabled {
    cursor: default;

    &:hover {
      background-color: unset;
    }
  }
}

.menuItemText {
  @extend .text-font-text;
  border-radius: $default-border-radius;

  position: relative;
  width: 80%;
  font-size: 0.8125rem;
  font-weight: $fw-medium;
  color: $colors-indigo;
  text-align: left;
  padding: 8px 14px;
  min-width: 7rem;

  &.disabled {
    opacity: 0.6;
    cursor: default;
  }
}

.inputItem {
  display: flex;
  align-items: flex-start;
  gap: $half-spacing;
  padding: $half-spacing $half-spacing 0 $half-spacing;

  button {
    height: 43.5px;
  }
}

.createListButton {
  width: 100%;
  margin-top: $quarter-spacing;
  text-align: left;
  font-weight: $fw-medium;

  &:hover {
    background-color: $colors-grey-light;
  }
}