@use 'styles/vars' as *;
@use 'styles/functions' as *;

.card {
  transition: filter 0.2s;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 5;
}

.companyDetails {
  list-style: none;
  border-top: 1px solid $colors-grey-medium;
  border-bottom: 1px solid $colors-grey-medium;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;

  >*:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.limitLabelWidth {
  max-width: 70vw;
}

.mission {
  @include clamp-lines(3, getLineHeight("body"));
  min-height: 5.01rem; // line-height * 3
}

.bannerImage {
  width: 100%;
  overflow: hidden;
  padding-bottom: 24%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}