@use 'styles/vars' as *;
@use 'styles/global/text';

.document {
  transition: all $transition-time $transition-hover;

  &:hover {
    color: $colors-blue;
  }
}

.text {
  font-family: text.$ff-display;
}

.tile {
  overflow: hidden;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $half-spacing;
}

.listItem:last-of-type {
  margin-bottom: 0;
}