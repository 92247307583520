@use 'styles/vars' as *;
@use 'styles/global/text' as *;

.button {
  @extend .text-font-text;
  text-align: left;
  border-radius: $default-border-radius;
  font-weight: 500;
  line-height: 1.38;
  min-height: 55px;
  border: 2px solid transparent;
  transition: background-color $transition-time-short $transition-ease-in-out 0ms,
    border $transition-time-short $transition-ease-in-out 0ms, color $transition-time-short $transition-ease-in-out 0ms,
    opacity $transition-time-short $transition-ease-in-out 0ms;

  position: relative;

  &:disabled {
    opacity: 0.6;
  }

  &:focus:not(:active) {
    outline-color: $colors-blue-medium;
    outline-width: 2px;
    outline-style: solid;
    -moz-outline-radius: 8px;
  }

  svg {
    margin-top: -8px; // Make sure icons don't make the button grow
    margin-bottom: -8px;
  }
}

.text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 1.4em;
}

.grey {
  color: $colors-grey-light;
}

.medium {
  padding: 8px 14px;
  font-size: $fs-small;
  min-width: 7rem;
}

.primary {
  color: $colors-indigo;

  &:disabled {
    color: $colors-indigo-medium;
  }

  &.grey {
    background-color: $colors-grey-light;

    &:hover:not(:disabled) {
      background-color: $colors-grey-medium;
    }

    &.selected {
      background-color: $colors-grey-medium;
    }
  }

  &.white {
    background-color: $colors-white;

    &:hover:not(:disabled) {
      background-color: $colors-grey-medium;
    }

    &.selected {
      background-color: $colors-grey-light;
    }
  }

  &.borderGrey {
    border-color: $colors-grey-medium;
  }
}

.menuItem {
  width: 100%;
  border-radius: 0;
  border-top: 1px solid $colors-grey-medium;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hasError {
  border-color: $colors-red;
}